import { PAGE_SIZE, STATUS } from "@/utils/constants";
import { viewFileFromServer } from "@/utils/functions";
import axios from "axios";
import PaginationData from "./../../general/PaginationData";
import JobAssignmentOrderImg from "@/assets/images/orders.svg";
import JobAssignmentOrdersFilter from "./JobAssignmentOrdersFilter";

export default class JobAssignmentOrder {
  defaultImg = JobAssignmentOrderImg;
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.jobAssignmentOrderToken = "";
    this.jobAssignmentOrderNameCurrent = "";
    this.jobAssignmentOrderNameEn = "";
    this.jobAssignmentOrderNameAr = "";
    this.jobAssignmentOrderNameUnd = "";
    this.jobAssignmentOrderCode = "";
    this.jobAssignmentOrderDescriptionCurrent = "";
    this.jobAssignmentOrderDescriptionEn = "";
    this.jobAssignmentOrderDescriptionAr = "";
    this.jobAssignmentOrderDescriptionUnd = "";
    this.jobAssignmentOrderNotes = "";
    this.employeeJobAssignmentOrderStartDateTime = "";
    this.employeeJobAssignmentOrderStartDate = "";
    this.employeeJobAssignmentOrderStartTime = "";
    this.employeeJobAssignmentOrderEndDateTime = "";
    this.employeeJobAssignmentOrderEndDate = "";
    this.employeeJobAssignmentOrderEndTime = "";
    this.employeeJobAssignmentOrderDuration = "";
    this.jobAssignmentOrderArchiveStatus = "";
    this.employeeMakeActionCode = "";
    this.employeeMakeActionToken = "";
    this.employeeMakeActionNameCurrent = "";
    this.employeeMakeActionImagePath = "";
    this.employeeMakeActionNationalNumber = "";
    this.employeeMakeActionNationalNumberIssuingAuthorityCurrent = "";
    this.employeeMakeActionIssuanceDateNationalNumber = "";
    this.employeeMakeActionExpiryDateNationalNumber = "";
    this.employeeMakeActionPassportNumber = "";
    this.employeeMakeActionPassportNumberIssuingAuthorityCurrent = "";
    this.employeeMakeActionIssuanceDatePassportNumber = "";
    this.employeeMakeActionExpiryDatePassportNumber = "";
    this.jobAssignmentOrderActionDatetime = "";
    this.jobAssignmentOrderActionDate = "";
    this.jobAssignmentOrderActionTime = "";
    this.jobAssignmentOrderActionDayNameCurrent = "";
    this.employeeToken = "";
    this.employeeNameCurrent = "";
    this.employeeCode = "";
    this.employeeImagePath = "";
    this.employeeNationalNumberStatus = false;
    this.employeeNationalNumber = "";
    this.employeeNationalNumberIssuingAuthorityCurrent = "";
    this.employeeNationalNumberIssuingAuthorityAr = "";
    this.employeeNationalNumberIssuingAuthorityEn = "";
    this.employeeNationalNumberIssuingAuthorityUnd = "";
    this.employeeIssuanceDateTimeNationalNumber = "";
    this.employeeIssuanceDateNationalNumber = "";
    this.employeeIssuanceTimeNationalNumber = "";
    this.employeeExpiryDateTimeNationalNumber = "";
    this.employeeExpiryDateNationalNumber = "";
    this.employeeExpiryTimeNationalNumber = "";
    this.employeePassportNumberStatus = false;
    this.employeePassportNumber = "";
    this.employeePassportNumberIssuingAuthorityCurrent = "";
    this.employeePassportNumberIssuingAuthorityAr = "";
    this.employeePassportNumberIssuingAuthorityEn = "";
    this.employeePassportNumberIssuingAuthorityUnd = "";
    this.employeeIssuanceDateTimePassportNumber = "";
    this.employeeIssuanceDatePassportNumber = "";
    this.employeeIssuanceTimePassportNumber = "";
    this.employeeExpiryDateTimePassportNumber = "";
    this.employeeExpiryDatePassportNumber = "";
    this.employeeExpiryTimePassportNumber = "";
    this.employeeAddressCurrent = "";
    this.employeeAddressAr = "";
    this.employeeAddressEn = "";
    this.employeeAddressUnd = "";

    this.sendTo = "";
  }
  fillData(data) {
    this.jobAssignmentOrderToken = data.jobAssignmentOrderToken;
    this.jobAssignmentOrderNameCurrent = data.jobAssignmentOrderNameCurrent;
    this.jobAssignmentOrderNameEn = data.jobAssignmentOrderNameEn;
    this.jobAssignmentOrderNameAr = data.jobAssignmentOrderNameAr;
    this.jobAssignmentOrderNameUnd = data.jobAssignmentOrderNameUnd;
    this.jobAssignmentOrderCode = data.jobAssignmentOrderCode;
    this.jobAssignmentOrderDescriptionCurrent =
      data.jobAssignmentOrderDescriptionCurrent;
    this.jobAssignmentOrderDescriptionEn = data.jobAssignmentOrderDescriptionEn;
    this.jobAssignmentOrderDescriptionAr = data.jobAssignmentOrderDescriptionAr;
    this.jobAssignmentOrderDescriptionUnd =
      data.jobAssignmentOrderDescriptionUnd;
    this.jobAssignmentOrderNotes = data.jobAssignmentOrderNotes;
    this.employeeJobAssignmentOrderStartDateTime =
      data.employeeJobAssignmentOrderStartDateTime;
    this.employeeJobAssignmentOrderStartDate =
      data.employeeJobAssignmentOrderStartDate;
    this.employeeJobAssignmentOrderStartTime =
      data.employeeJobAssignmentOrderStartTime;
    this.employeeJobAssignmentOrderEndDateTime =
      data.employeeJobAssignmentOrderEndDateTime;
    this.employeeJobAssignmentOrderEndDate =
      data.employeeJobAssignmentOrderEndDate;
    this.employeeJobAssignmentOrderEndTime =
      data.employeeJobAssignmentOrderEndTime;
    this.employeeJobAssignmentOrderDuration =
      data.employeeJobAssignmentOrderDuration;
    this.jobAssignmentOrderArchiveStatus = data.jobAssignmentOrderArchiveStatus;
    this.employeeMakeActionCode = data.employeeMakeActionCode;
    this.employeeMakeActionToken = data.employeeMakeActionToken;
    this.employeeMakeActionNameCurrent = data.employeeMakeActionNameCurrent;
    this.employeeMakeActionImagePath = data.employeeMakeActionImagePath;
    this.employeeMakeActionNationalNumber =
      data.employeeMakeActionNationalNumber;
    this.employeeMakeActionNationalNumberIssuingAuthorityCurrent =
      data.employeeMakeActionNationalNumberIssuingAuthorityCurrent;
    this.employeeMakeActionIssuanceDateNationalNumber =
      data.employeeMakeActionIssuanceDateNationalNumber;
    this.employeeMakeActionExpiryDateNationalNumber =
      data.employeeMakeActionExpiryDateNationalNumber;
    this.employeeMakeActionPassportNumber =
      data.employeeMakeActionPassportNumber;
    this.employeeMakeActionPassportNumberIssuingAuthorityCurrent =
      data.employeeMakeActionPassportNumberIssuingAuthorityCurrent;
    this.employeeMakeActionIssuanceDatePassportNumber =
      data.employeeMakeActionIssuanceDatePassportNumber;
    this.employeeMakeActionExpiryDatePassportNumber =
      data.employeeMakeActionExpiryDatePassportNumber;
    this.jobAssignmentOrderActionDatetime =
      data.jobAssignmentOrderActionDatetime;
    this.jobAssignmentOrderActionDate = data.jobAssignmentOrderActionDate;
    this.jobAssignmentOrderActionTime = data.jobAssignmentOrderActionTime;
    this.jobAssignmentOrderActionDayNameCurrent =
      data.jobAssignmentOrderActionDayNameCurrent;
    this.employeeToken = data.employeeToken;
    this.employeeNameCurrent = data.employeeNameCurrent;
    this.employeeCode = data.employeeCode;
    this.employeeImagePath = data.employeeImagePath;
    this.employeeNationalNumberStatus = data.employeeNationalNumberStatus;
    this.employeeNationalNumber = data.employeeNationalNumber;
    this.employeeNationalNumberIssuingAuthorityCurrent =
      data.employeeNationalNumberIssuingAuthorityCurrent;
    this.employeeNationalNumberIssuingAuthorityAr =
      data.employeeNationalNumberIssuingAuthorityAr;
    this.employeeNationalNumberIssuingAuthorityEn =
      data.employeeNationalNumberIssuingAuthorityEn;
    this.employeeNationalNumberIssuingAuthorityUnd =
      data.employeeNationalNumberIssuingAuthorityUnd;
    this.employeeIssuanceDateTimeNationalNumber =
      data.employeeIssuanceDateTimeNationalNumber;
    this.employeeIssuanceDateNationalNumber =
      data.employeeIssuanceDateNationalNumber;
    this.employeeIssuanceTimeNationalNumber =
      data.employeeIssuanceTimeNationalNumber;
    this.employeeExpiryDateTimeNationalNumber =
      data.employeeExpiryDateTimeNationalNumber;
    this.employeeExpiryDateNationalNumber =
      data.employeeExpiryDateNationalNumber;
    this.employeeExpiryTimeNationalNumber =
      data.employeeExpiryTimeNationalNumber;
    this.employeePassportNumberStatus = data.employeePassportNumberStatus;
    this.employeePassportNumber = data.employeePassportNumber;
    this.employeePassportNumberIssuingAuthorityCurrent =
      data.employeePassportNumberIssuingAuthorityCurrent;
    this.employeePassportNumberIssuingAuthorityAr =
      data.employeePassportNumberIssuingAuthorityAr;
    this.employeePassportNumberIssuingAuthorityEn =
      data.employeePassportNumberIssuingAuthorityEn;
    this.employeePassportNumberIssuingAuthorityUnd =
      data.employeePassportNumberIssuingAuthorityUnd;
    this.employeeIssuanceDateTimePassportNumber =
      data.employeeIssuanceDateTimePassportNumber;
    this.employeeIssuanceDatePassportNumber =
      data.employeeIssuanceDatePassportNumber;
    this.employeeIssuanceTimePassportNumber =
      data.employeeIssuanceTimePassportNumber;
    this.employeeExpiryDateTimePassportNumber =
      data.employeeExpiryDateTimePassportNumber;
    this.employeeExpiryDatePassportNumber =
      data.employeeExpiryDatePassportNumber;
    this.employeeExpiryTimePassportNumber =
      data.employeeExpiryTimePassportNumber;
    this.employeeAddressCurrent = data.employeeAddressCurrent;
    this.employeeAddressAr = data.employeeAddressAr;
    this.employeeAddressEn = data.employeeAddressEn;
    this.employeeAddressUnd = data.employeeAddressUnd;
  }

  async getAllJobAssignmentOrders(
    language,
    userAuthorizeToken,
    pagination = new PaginationData(),
    filterData = new JobAssignmentOrdersFilter()
  ) {
    try {
      let response = await axios.get(
        `/JobAssignmentOrders/GetAllJobAssignmentOrders?language=${language}&userAuthorizeToken=${userAuthorizeToken}&jobAssignmentOrderArchiveStatus=false&page=${pagination.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&employeeToken=${filterData.employeeToken}&textSearch=${filterData.textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfJobAssignmentOrders(
    language,
    userAuthorizeToken,
    filterData = new JobAssignmentOrdersFilter()
  ) {
    return await axios.get(
      `/JobAssignmentOrders/GetDialogOfJobAssignmentOrders?language=${language}&userAuthorizeToken=${userAuthorizeToken}&jobAssignmentOrderArchiveStatus=false&filterStatus=true&employeeToken=${filterData.employeeToken}&textSearch=${filterData.textSearch}`
    );
  }

  async getJobAssignmentOrderDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/JobAssignmentOrders/GetJobAssignmentOrderDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&jobAssignmentOrderToken=${this.jobAssignmentOrderToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async printJobAssignmentOrder(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/JobAssignmentOrders/PrintJobAssignmentOrder?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&jobAssignmentOrderToken=${this.jobAssignmentOrderToken}&sendTo=${this.sendTo}`
      );
      if (response.data.status == STATUS.SUCCESS) {
        viewFileFromServer(response.data.reportPath);
      }
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateJobAssignmentOrder(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      jobAssignmentOrderToken: this.jobAssignmentOrderToken,
      jobAssignmentOrderCode: this.jobAssignmentOrderCode,
      employeeToken: this.employeeToken,
      employeeJobAssignmentOrderStartDate:
        this.employeeJobAssignmentOrderStartDate,
      employeeJobAssignmentOrderStartTime:
        this.employeeJobAssignmentOrderStartTime,
      employeeJobAssignmentOrderEndDate: this.employeeJobAssignmentOrderEndDate,
      employeeJobAssignmentOrderEndTime: this.employeeJobAssignmentOrderEndTime,
      jobAssignmentOrderDescriptionEn: this.jobAssignmentOrderDescriptionEn,
      jobAssignmentOrderDescriptionAr: this.jobAssignmentOrderDescriptionAr,
      jobAssignmentOrderDescriptionUnd: this.jobAssignmentOrderDescriptionUnd,
      jobAssignmentOrderNotes: this.jobAssignmentOrderNotes,
    };

    try {
      if (
        this.jobAssignmentOrderToken == "" ||
        this.jobAssignmentOrderToken == undefined
      ) {
        return await axios.post(
          `/JobAssignmentOrders//AddJobAssignmentOrder`,
          data
        );
      } else {
        return await axios.post(
          `/JobAssignmentOrders//UpdateJobAssignmentOrder`,
          data
        );
      }
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveJobAssignmentOrder(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      jobAssignmentOrderToken: this.jobAssignmentOrderToken,
    };

    try {
      return await axios.post(
        `/JobAssignmentOrders//FinalDeleteJobAssignmentOrder`,
        data
      );
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
